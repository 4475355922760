import axios from "axios";
// const BASE_URL = `http://91.205.172.123:7512`;
// const BASE_URL=`http://localhost:7875`;
const BASE_URL = `https://www.backend.learnablekids.com`; 
// const BASE_URL = `http://192.168.1.32:7875`;
// const BASE_URL = `http://176.9.90.155:8121`;
// const BASE_URL = `http://176.9.90.155:8121/LearnableKidsTesting`;



export async function loginApi(data) {
  const response = await axios.post(`${BASE_URL}/api/v1/login`, data);
  return response;
}

export async function subscribeApi(data) {
  const response = await axios.post(`${BASE_URL}/api/v1/subscribe`, data);
  return response;
}

export async function matchOtpApi(data) {
  const response = await axios.post(`${BASE_URL}/api/v1/MatchOtpRequest`, data);
  return response;
}

export async function priceApi() {
  const response = await axios.get(`${BASE_URL}/api/v1/price`);
  return response;
}

export async function unsubApi(ani, token) {
  const response = await axios.post(`${BASE_URL}/api/v1/auth/unsub`, ani, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
}

export async function termsApi(token,currency) {

  const response = await axios.post(
    `${BASE_URL}/api/v1/auth/sendTerms`,
    {
      serviceId: "1",
      type: "terms",
      currency: currency,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
}

export async function questionsApi(token,currency) {
  const response = await axios.post(
    `${BASE_URL}/api/v1/auth/sendTerms`,
    {
      serviceId: "1",
      type: "faq",
      currency: currency,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
}

export async function packInfoApi(ani, token) {

  const response = await axios.get(`${BASE_URL}/api/v1/auth/pack?ani=${ani}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
}

export async function changeCurrencyApi(data) {
  let sendResponse;
  await axios.post(`${BASE_URL}/api/v1/changeCurrency`, data).then(
    (response) => {
      //    console.log("response||| ",response);
      sendResponse = response;
    },
    (error) => {
      // console.log(error);
      sendResponse = error.response;
    }
  );
  return sendResponse;
}

export async function getCurrencyPackApi(currencyValue) {
  const response = await axios.get(
    `${BASE_URL}/api/v1/price?currency=${currencyValue}`
  );
  return response;
}

// const response = await axios.post(`${BASE_URL}/unsub`, ani, {
//   headers: {
//     Authorization: `Bearer ${token}`,
//   },
// });

// const response = await axios.post(
//   `${BASE_URL}/sendTerms`,
//   {
//     serviceId: "1",
//     type: "terms",
//   },
//   {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   }
// );

// const response = await axios.post(
//   `${BASE_URL}/sendTerms`,
//   {
//     serviceId: "1",
//     type: "faq",
//   },
//   {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   }
// );

// const response = await axios.get(`${BASE_URL}/pack?ani=${ani}`, {
//   headers: {
//     Authorization: `Bearer ${token}`,
//   },
// });
