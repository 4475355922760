import React, { useState } from "react";
import classes from "../css/LoginPage.module.css";
import Layout from "../components/Layout";
import { useNavigate } from "react-router-dom";
import TopLines from "../components/TopLines";
import SubLayout from "../components/SubLayout";
import { toast } from "react-toastify";
import { changeCurrencyApi, loginApi } from "../http/http";
import ModalInfo from "../components/ModalInfo";
import Loader from "../components/Loader";
import Cookies from "js-cookie";
import { setCookie, setCookieUserId } from "../utils/helper";

const LoginPage = () => {
  const [msisdn, setMsisdn] = useState("");
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [modalInfo, setModalInfo] = useState("");
  const [subscribeBtn, setSubscribeBtn] = useState(false);
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState({
    state: false,
    switchTo: "",
    stayWith: "",
    msisdn: "",
  });

  const [currencyOptions, setCurrencyOptions] = useState([
    { value: "USD", label: "United States Dollar (USD)" },
    { value: "ZWG", label: "Zimbabwe Gold (ZWG)" },
  ]);

  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const pressHandler = () => {
    setModal(false);
    setModalInfo("");
    setSubscribeBtn(false);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (msisdn.trim().length == 0 || !selectedCurrency) {
      // toast.error("Input Fields cannot be empty!");
      localStorage.setItem("token", "token");
      Cookies.set("token", "token", { expires: 1 });
      setModal(true);
      setModalInfo("Input Fields cannot be empty!");
    } else {
      try {
        setLoading(true);
        const response = await loginApi({
          ani: msisdn,
          currency: selectedCurrency?.value,
        });
        console.log(response);

        // Extract response data and status
        const responseData = response?.data;
        const responseStatus = response?.status;

        // Handle the response based on the status code
        switch (responseStatus) {
          case 200: // OK
            if (responseData === "Login successful. OTP sent.") {
              // Handle successful login and OTP sent
              // setCookieUserId(response?.data.userId);  // Ensure `userId` is set correctly in response
              navigate("/otp", {
                state: {
                  msisdn: msisdn,
                  pack: null,
                  currency: selectedCurrency?.value,
                },
              });
            } else {
              // Handle other successful responses if needed
              setModal(true);
              setModalInfo(responseData || "Success");
            }
            break;

          case 403: // FORBIDDEN
            setModal(true);
            setModalInfo("You are not subscribed!");
            setSubscribeBtn(true);
            break;

          case 404: // NOT_FOUND
            setModal(true);
            setModalInfo(
              "User not found. Please subscribe to access services."
            );
            break;

          case 402: // PAYMENT_REQUIRED
            setModal(true);
            setModalInfo("Oops, you have insufficient balance!");
            break;

          case 409:
            console.log("enter here...");
            let optionUserIsSubscribed = currencyOptions.filter(
              (data) => data?.value != selectedCurrency?.value
            );
            setMessage({
              state: true,
              switchTo: selectedCurrency?.value,
              stayWith:
                optionUserIsSubscribed.length > 0 &&
                optionUserIsSubscribed[0]?.value,
              msisdn: msisdn,
            });
            break;

          default:
            // Handle any other unexpected status codes
            setModal(true);
            setModalInfo("An unexpected error occurred.");
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error?.response?.status === 403) {
          setModal(true);
          setModalInfo("You are not subscribed!");
          setSubscribeBtn(true);
        } else if (error?.response?.status === 404) {
          setModal(true);
          setModalInfo(
            "Subscription not found. Please subscribe the services."
          );
          setSubscribeBtn(true);
        } else if (error?.response?.status === 402) {
          setModal(true);
          setModalInfo("Oops, you have insufficient balance!");
        } else if (error?.response?.status === 409) {
          console.log("enter here 2");
          let optionUserIsSubscribed = currencyOptions.filter(
            (data) => data?.value != selectedCurrency?.value
          );
          setMessage({
            state: true,
            switchTo: selectedCurrency?.value,
            stayWith:
              optionUserIsSubscribed.length > 0 &&
              optionUserIsSubscribed[0]?.value,
            msisdn: msisdn,
          });
        } else {
          setModal(true);
          setModalInfo(
            error?.response?.data?.message ||
              error?.message ||
              "An unexpected error occurred."
          );
          // toast.error(
          //   error?.response?.data?.message ||
          //     error?.message ||
          //     "An unexpected error occurred."
          // );
        }
      }
    }
  };
  const navigateHandler = (path) => {
    navigate(path);
  };

  const handleReset = () => {
    setMessage({
      state: false,
      switchTo: "",
      stayWith: "",
      msisdn: "",
    });
  };

  const loginApiHandler = async (currencyFromParam) => {
    try {
      console.log(currencyFromParam, "cfp");
      setLoading(true);
      const response = await loginApi({
        ani: msisdn,
        currency: currencyFromParam,
      });
      console.log(response);

      // Extract response data and status
      const responseData = response?.data;
      const responseStatus = response?.status;

      // Handle the response based on the status code
      switch (responseStatus) {
        case 200: // OK
          if (responseData === "Login successful. OTP sent.") {
            // Handle successful login and OTP sent
            // setCookieUserId(response?.data.userId);  // Ensure `userId` is set correctly in response
            navigate("/otp", {
              state: {
                msisdn: msisdn,
                pack: null,
                currency: currencyFromParam,
              },
            });
          } else {
            // Handle other successful responses if needed
            setModal(true);
            setModalInfo(responseData || "Success");
          }
          break;

        case 403: // FORBIDDEN
          setModal(true);
          setModalInfo("You are not subscribed!");
          setSubscribeBtn(true);
          break;

        case 404: // NOT_FOUND
          setModal(true);
          setModalInfo("User not found. Please subscribe to access services.");
          break;

        case 402: // PAYMENT_REQUIRED
          setModal(true);
          setModalInfo("Oops, you have insufficient balance!");
          break;

        case 409:
          console.log("enter here...");
          let optionUserIsSubscribed = currencyOptions.filter(
            (data) => data?.value != selectedCurrency?.value
          );
          setMessage({
            state: true,
            switchTo: selectedCurrency?.value,
            stayWith:
              optionUserIsSubscribed.length > 0 &&
              optionUserIsSubscribed[0]?.value,
            msisdn: msisdn,
          });
          break;

        default:
          // Handle any other unexpected status codes
          setModal(true);
          setModalInfo("An unexpected error occurred.");
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 403) {
        setModal(true);
        setModalInfo("You are not subscribed!");
        setSubscribeBtn(true);
      } else if (error?.response?.status === 404) {
        setModal(true);
        setModalInfo("Subscription not found. Please subscribe the services.");
        setSubscribeBtn(true);
      } else if (error?.response?.status === 402) {
        setModal(true);
        setModalInfo("Oops, you have insufficient balance!");
        navigate("/login")
      } else if (error?.response?.status === 409) {
        console.log("enter here 2");
        let optionUserIsSubscribed = currencyOptions.filter(
          (data) => data?.value != selectedCurrency?.value
        );
        setMessage({
          state: true,
          switchTo: selectedCurrency?.value,
          stayWith:
            optionUserIsSubscribed.length > 0 &&
            optionUserIsSubscribed[0]?.value,
          msisdn: msisdn,
        });
      } else {
        setModal(true);
        setModalInfo(
          error?.response?.data?.message ||
            error?.message ||
            "An unexpected error occurred."
        );
        // toast.error(
        //   error?.response?.data?.message ||
        //     error?.message ||
        //     "An unexpected error occurred."
        // );
      }
    }
  };

  const handelwithsame = async () => {
    const currency = message.stayWith;
    loginApiHandler(currency);
  };

  const handlingResponseforChange = (response) => {
    console.log("response: ", response);
    const { status, data } = response; // Destructure response status and data

    console.log("response..", status);
    console.log("data..", data);

    switch (status) {
      case 200:
      case 202: // OK
        toast.success("Currency Changed successfully");
        setMessage({
          state: false,
        });
        loginApiHandler(message.switchTo);
        break;
      case 500: // Internal Server Error (or other server errors)
      case "Network Error": // Network Error case
        setLoading(false);
        navigate("/error");
        break;
      case 409: // Network Error case
        setLoading(false);
        let optionUserIsSubscribed = currencyOptions.filter(
          (data) => data?.value != selectedCurrency?.value
        );
        setMessage({
          state: true,
          switchTo: selectedCurrency?.value,
          stayWith:
            optionUserIsSubscribed.length > 0 &&
            optionUserIsSubscribed[0]?.value,
          msisdn: msisdn,
        });
        // toast.error(data);
        // setOpenModal(true);
        break;

      case 404: // Not Found
        setLoading(false);
        setModal(true);
        setModalInfo("An unexpected error occurred.");
        break;

      case 402: // Charging Failed
        console.log("402----", status);
        setLoading(false);
        toast.error(data);
        setModal(true);
        setModalInfo(data);
        // Redirect to login after 2 seconds
        setTimeout(() => {
          navigate("/login");
        }, 2000);
        break;

      default:
        setLoading(false);

        break;
    }
  };

  const handleChangeCurrency = async () => {
    console.log(message.switchTo);
    let request = { ani: msisdn, currency: message.switchTo };

    const response = await changeCurrencyApi(request);
    handlingResponseforChange(response);

    // const response = await Post(changeCurrency, request);
    // console.log("response: ", response);
    // handlingResponseforChange(response);
  };

  return (
    <Layout>
      <TopLines />
      <SubLayout>
        <div className={classes.title_container}>
          <img
            src="/assets/images/cartoon.png"
            alt="cartoon"
            className={classes.cartoon_image}
          />
          <img
            src="/assets/images/name.png"
            alt="name"
            className={classes.name_image}
          />
        </div>

        <div className={classes.tabs_container}>
          <div className={classes.tabs_sub_container}>
            <div
              className={classes.tab_1}
              onClick={() => navigateHandler("/login")}
            >
              <p className={classes.tab}>Login</p>
            </div>
            <div
              className={classes.tab_2}
              onClick={() => navigateHandler("/subscribe")}
            >
              <p className={classes.tab}>Subscribe</p>
            </div>
          </div>
        </div>

        {message?.state == true ? (
          <div className={classes.message_box}>
            <div className={classes.back_btn} onClick={handleReset}>
              &#8617;
            </div>
            <div className={classes.wrapper_2}>
              <p className={classes.message_primary}>
                YOU ARE ALREADY SUBSCRIBED WITH {message.stayWith}
                <p className={classes.message_secondary}>DO YOU WISH TO:</p>
              </p>
              <button
                type="button"
                className={classes.switch_btn}
                disabled={loading}
                onClick={() => handleChangeCurrency()}
              >
                {`SWITCH TO ${message.switchTo}`}
              </button>
              <button
                type="button"
                className={classes.stay_with_btn}
                disabled={loading}
                onClick={() => handelwithsame(message.stayWith)}
              >
                {`STAY WITH ${message.stayWith}`}
              </button>
            </div>
          </div>
        ) : (
          <div className={classes.form_container}>
            <form className={classes.form} onSubmit={submitHandler}>
              <div className={classes.input_group}>
                <span className={classes.country_code}>+263</span>
                <input
                  className={classes.input}
                  type="number"
                  placeholder="ENTER YOUR PHONE NUMBER"
                  value={msisdn}
                  onChange={(e) => setMsisdn(e.target.value)}
                ></input>
              </div>

              <div className={classes.dropdown_selector}>
                <div className={classes.selector}>
                  <p className={classes.selected_value}>
                    {selectedCurrency != null
                      ? selectedCurrency?.label
                      : "Select a currency"}
                  </p>
                  <div className={classes.wrapper}>
                    <div className={classes.line_breaker}></div>
                    <div className={classes.chevron_down}></div>
                  </div>
                </div>

                <div className={classes.selector_options}>
                  {currencyOptions.map((optionValue, idx) => {
                    return (
                      <div
                        className={`${classes.option} ${
                          selectedCurrency?.value == optionValue?.value &&
                          classes.selected
                        }`}
                        key={idx}
                        onClick={() => setSelectedCurrency(optionValue)}
                      >
                        <p className={classes.option_text}>
                          {optionValue?.label}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>

              <button type="submit" className={classes.login_btn}>
                Login
              </button>
            </form>
          </div>
        )}

        {loading && <Loader />}
        <div className={classes.footer_container}>
          <div className={classes.footer_sub_container}>
            <img
              src="/assets/images/econet_logo.png"
              alt="econet"
              className={classes.footer}
            />
            <p className={classes.footer_text}>
              By clicking subscribe, you have read, understood and agree to be
              bound by the MyKidzHub service’s Terms & Conditions and FAQ’s
            </p>
          </div>
        </div>
        {modal && (
          <ModalInfo
            text={modalInfo}
            pressHandler={pressHandler}
            subscribeBtn={subscribeBtn}
          />
        )}
      </SubLayout>
    </Layout>
  );
};

export default LoginPage;
